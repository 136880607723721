import React, { useEffect, useState, lazy, Suspense } from "react";
import "./styles/card.css";
import {
  Card,
  ListGroup,
  Button,
  Tabs,
  Tab,
  Row,
  FormControl,
  Modal,
} from "react-bootstrap";
import subscriptions from "../../services/SubscriptionClass";
import StripeWidget from "./StripeWidget";
import StrexWidget from "./StrexWidget";
import msclogo from "../../images/mc_symbol.svg";
import visalogo from "../../images/Visa_2021.svg";
import strexlogo from "../../images/strexlogo.jpg";
import vippslogo from "../../images/vipps-logo.jpg";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import { useHistory } from "react-router";
const VippsWidget = lazy(() => import('./VippsWidget'));

const PaymentCard = ({
  title,
  currentUser,
  setCurrentUser,
  subscription,
  setSubscription,
}) => {
  const msisdn_string =
    "+" +
    currentUser.mobile.substring(0, 2) +
    " " +
    currentUser.mobile.substring(2, 5) +
    " " +
    currentUser.mobile.substring(5, 7) +
    " " +
    currentUser.mobile.substring(7, 10);
  const [campaign, setCampaign] = useState(false);
  
 /* const campaignProducts = subscriptions.filter(
    (subscriptionobject) =>
      subscriptionobject.campaign === true
  )*/
  /*const campaignAvsluttStartDate = '';
  const campaignAvsluttEndDate = '';
  const campaignAvsluttExceptionProduct = '';
  const campaignAvsluttExceptionProductt = useState([]);*/

  const currentDate = new Date();
  const campaignAvsluttProduct = subscriptions.filter(
    (subscriptionobject) =>
      subscriptionobject.campaignPosition === "avslutt" && subscriptionobject.campaign === true 
      && currentDate.getTime() > new Date(subscriptionobject.startDate).getTime() 
        &&  new Date(subscriptionobject.endDate).getTime() > currentDate.getTime()
  )
//  console.log("campaignAvsluttProduct", campaignAvsluttProduct);
///&& currentDate.getTime() > subscriptionobject.startDate.getTime() && subscriptionobject.endDate.getTime() > currentDate.getTime()

  //if(campaignAvsluttProduct.length > 0){
    const campaignAvsluttStartDate  = campaignAvsluttProduct.length > 0 ? new Date(campaignAvsluttProduct[0].startDate) : '';
    const campaignAvsluttEndDate    = campaignAvsluttProduct.length > 0 ? new Date(campaignAvsluttProduct[0].endDate) : '';
    const campaignAvsluttExceptionProduct  = campaignAvsluttProduct.length > 0 ? campaignAvsluttProduct[0].exceptionProduct : '';
    const campaignAvsluttExceptionProductt = campaignAvsluttExceptionProduct.split(", ");
  //} 
 

  const subscriptionobject = subscriptions.filter(
    (subscriptionobject) =>
      subscriptionobject.id.toString() === subscription.product_id
  );
  console.log("campaignAvsluttProduct", campaignAvsluttProduct);
  const [paymentExpand, setPaymentExpand] = useState(false);
  const [orderExpand, setOrderExpand] = useState(false);
  const [giftcardExpand, setGiftcardExpand] = useState(false);
  const [giftcardCode, setGiftcardCode] = useState("");
  const [terminateSubExpand, setTerminateSubExpand] = useState(false);
  const [subState, setSubState] = useState(true);
  const history = useHistory();
  const [campaignExpiry, setCampaignExpiry] = useState("");
  const [popupMsg, setPopupMsg] = useState(false);

  const [selectedID, setSelectedID] = useState("");
  const [popupChangeSub, setPopupChangeSub] = useState(false);
  const [chosenProd, setChosenProd] = useState("");
  const [disableMode, setDisableMode] = useState(false);
  const [changeSubState, setChangeSubState] = useState(false);
  const [popupForny, setPopupForny] = useState(false);
  const [popupRenewSub, setPopupRenewSub] = useState(false);
  const [popupChangeSubKvitering, setPopupChangeSubKvitering] = useState(false);
  const [popupFornyKvitering, setPopupFornyKvitering] = useState(false);
  const [offer601Widget, set601OfferWidget] = useState(false);
  let terminateExecution = 0;
  //const shouldLoadVippsComponent = {{currentUser.payment_type === 6 ? true : false}};

  const [userMessage, setUserMessage] = useState({
    showMessage: false,
    message: "",
    messageType: null,
  });


  const handle4003Product = (e) => {
    //console.log('christmas');
    setSelectedID(4003);
    setPopupChangeSub(true);
    setChosenProd(4003);
  };
  const handleCampaignProduct = (id) => {
    //console.log("campaginProductID", id);
    setSelectedID(String(id));
    setPopupChangeSub(true);
    setChosenProd(id);
  }
  const handle601Product = (e) => {
    //console.log('christmas');
    setSelectedID("601");
    setPopupChangeSub(true);
    setChosenProd("601");
  };

  const handle603Product = (e) => {
    //console.log('christmas');
    setSelectedID("603");
    setPopupChangeSub(true);
    setChosenProd("603");
  };

  const handle1002Product = (e) => {
    //console.log('christmas');
    setSelectedID("1002");
    setPopupChangeSub(true);
    setChosenProd("1002");
  };

  const handle605Product = (e) => {
    //console.log('christmas');
    setSelectedID("605");
    setPopupChangeSub(true);
    setChosenProd("605");
  };

  const handleCloseM = () => {
    setPopupChangeSub(false);
    setPopupForny(false);
    setPopupFornyKvitering(false);
    setPopupChangeSubKvitering(false);
  };
  
  const vippsData = {
      "product_id": selectedID,
      "campaign_code": "UTMCAMPAIGN",
      "redirect_url": window.location.origin+"/vipps-completed"
    }
  //console.log("vippsData", vippsData);
  const handleChangeSub = () => {
    //console.log("vippsData", vippsData);
    if(currentUser.payment_type === 6) {
        const currentToken = AuthService.getCurrentToken();
          UserService.vippsIntent(currentToken.access_token, vippsData).then(
            (vipps) => {
              if(vipps.status===200){
                window.sessionStorage.setItem('vippsreference', vipps.data.reference);
                window.location.href = vipps.data.confirmation_url
                //setVippsButtonUrl(vipps.data.confirmation_url);
              } else {
              }
            }
          );
    } else {
      setDisableMode(true);
      if (subState == "") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "subscription_reactivated",
        });
      }
      if (subState === "STOPPED" && selectedID === subscription.product_id) {
        //handleActivateSubscription();
      } else {
        const currentToken = AuthService.getCurrentToken();
        if(currentUser.payment_type === 3){
          
          UserService.changeStripeProduct(currentToken.access_token, selectedID)
          .then(response => {
            UserService.getSubscription(currentToken.access_token).then(
              (subscription) => {
                UserService.userSubscriptionLog("Payment Page 1", JSON.stringify(subscription),  JSON.stringify(currentUser));
                setSubscription(subscription);
                handleCloseM();
                window.location.reload();
              },
              (error) => {
                UserService.userSubscriptionLog("Payment Page 1", JSON.stringify(error),  JSON.stringify(currentUser));
                //console.log(error);
                //console.log("error geting subscription 4.0");
              }
            );
            setPopupForny(false);
            setPopupRenewSub(false);
            setPopupChangeSub(false);
            setDisableMode(false);
            setChangeSubState(true);
           // console.log("error geting subscription 2");
            setPopupChangeSubKvitering(true);

          },(error) => {
            setDisableMode(false);
            setChangeSubState(false);
            setPopupChangeSubKvitering(true);
            //console.log("error geting subscription 4.3");
          });
        } else {
          UserService.changeStrexProduct(currentToken.access_token, selectedID)
          .then(response => {
            //UserService.paymentSwitchLogging(response, currentUser.mobile, subState, selectedID);
            UserService.getSubscription(currentToken.access_token).then(
              (subscription) => {
                UserService.userSubscriptionLog("Payment Page 2", JSON.stringify(subscription),  JSON.stringify(currentUser));
                setSubscription(subscription);
                handleCloseM();
                window.location.reload();
              },
              (error) => {
                UserService.userSubscriptionLog("Payment Page 2", JSON.stringify(error),  JSON.stringify(currentUser));
                //console.log(error);
                //console.log("error geting subscription 5.0");
              }
            );
           setPopupForny(false);
            setPopupRenewSub(false);
            setPopupChangeSub(false);
            setDisableMode(false);
            setChangeSubState(true);
           // console.log("error geting subscription 2");
            setPopupChangeSubKvitering(true);

          },(error) => {
            //UserService.paymentSwitchLogging(error, currentUser.mobile, subState, selectedID);
            setDisableMode(false);
            setChangeSubState(false);
            setPopupChangeSubKvitering(true);
            //console.log("error geting subscription 5.3");
          });
        }
        
        /*UserService.changeSubscription(
          currentToken.access_token,
          selectedID
        ).then(
          (response) => {
            UserService.getSubscription(currentToken.access_token).then(
              (subscription) => {
                setSubscription(subscription);
                handleCloseM();
              },
              (error) => {
                //console.log(error);
                //console.log("error geting subscription 2.0");
              }
            );
            setPopupForny(false);
            setPopupRenewSub(false);
            setPopupChangeSub(false);
            setDisableMode(false);
            setChangeSubState(true);
           // console.log("error geting subscription 2");
            setPopupChangeSubKvitering(true);
          },
          () => {
            //console.log("error activating subscription 2");
            setDisableMode(false);
            setChangeSubState(false);
            setPopupChangeSubKvitering(true);
          }
        );*/
      }
    }
  };


  useEffect(() => {
    const dateString = subscription.expiry_date; // ISO 8601 format
    const expiryDateObje = new Date(dateString);
    const currentDate = new Date();
    /*if(
      subscription.product_id === "11" ||
      subscription.product_id === "21" ||
      subscription.product_id === "31" ||
      subscription.product_id === "101" ||
      subscription.product_id === "102" ||
      subscription.product_id === "201" ||
      subscription.product_id === "202" ||
      subscription.product_id === "103" ||
      subscription.product_id === "701" ||
      subscription.product_id === "702" ||
      subscription.product_id === "110" ||
      subscription.product_id === "104" ||
      subscription.product_id === "105" ||
      subscription.product_id === "106" ||
      subscription.product_id === "3002" ||
      subscription.product_id === "107" ||
      subscription.id === 99
    ) {
     // setCampaign(false);
    } else {
    //  setCampaign(true);
    }*/

    if (subscription.state === "3") {
      setSubState("ACTIVE");
    }
    if (subscription.state === "1") {
      setSubState("TRIALING");
    }
    if (subscription.id === 99) {
      setSubState("FALSE");
    }
    if (subscription.state === "10" || subscription.state === "9") {
      setSubState("DEACTIVATED");
    //setProductID(subscription.product_id);
    }
    
    if (dateString!=null && expiryDateObje < currentDate) {
      setSubState("DEACTIVATED");
    }
    if ((subscription.state === "10" || subscription.state === "9") && expiryDateObje < currentDate) {
      setSubState("EXPIREDDEACTIVATED");
    //setProductID(subscription.product_id);
    }
    if (subscription.state === "6" && expiryDateObje > currentDate) {
      setSubState("STOPPED");
    }
    if (subscription.state === "5") {
      setSubState("RENEWFAIL");
    }

    if (subscription.product_id === "410") {
      setCampaignExpiry("september 2022");
    } else {
      try {
        setCampaignExpiry(subscriptionobject[0].campaignExpiry);
      } catch (err) {
        setCampaignExpiry("-");
      }
    }
  }, [subscription, setSubscription]);

  const toggle = (toggleReference) => {
    switch (toggleReference) {
      case "payment":
        setPaymentExpand(!paymentExpand);
        break;
      case "order-history":
        setOrderExpand(!orderExpand);
        break;
      case "giftcard":
        setGiftcardExpand(!giftcardExpand);
        break;
      case "terminate-sub":
        setTerminateSubExpand(!terminateSubExpand);
        break;
      default:
        break;
    }
  };

  const clearMessage = () => {
    setUserMessage({
      showMessage: false,
      message: "",
      messageType: null,
    });
  };
  const handlePopup = () => {
    setPopupMsg(true);
  };
  const formatDateString = (string) => {
    if (typeof string != "undefined" && string != null) {
      const day = string.substring(8, 10);
      const month = string.substring(5, 7);
      const year = string.substring(0, 4);
      return day + "." + month + "." + year;
    }
    return null;
  };

  const formatDateStringOneMonthBack = (string) => {
    if (typeof string != "undefined" && string != null) {
      const day = string.substring(8, 10);
      var month = parseInt(string.substring(5, 7)) - 1;
      var year = parseInt(string.substring(0, 4));
      if (month == "0") {
        month = 12;
        year = year - 1;
      }
      return day + "." + month + "." + year;
    }
    return null;
  };

  const goToStrex = () => {
    window.location.href = "http://strex.no";
  };

  const renderSwitch = (payment_type) => {
    switch (payment_type) {
      case 0:
        return (
          <>
            :
            <br />
            <br />
            <img src={strexlogo} alt="strex-logo" className="payment-logo" />
            <div className="bold-text">{msisdn_string}</div>
          </>
        );
      case 3:
        return (
          <>
            :
            <br />
            <img src={visalogo} alt="visa-logo" className="payment-logo" />
            <img src={msclogo} alt="mastercard-logo" className="payment-logo" />
          </>
        );
      case 6:
        return (
          <>
            :
            <br />
            <img src={vippslogo} alt="visa-logo" className="payment-logo" />
          </>
        );
      default:
        return (
          <>
            : <div className="bold-text">Faktura.</div>
          </>
        );
    }
  };

  const handleGiftcardChange = (e) => {
    setGiftcardCode(e.currentTarget.value);
  };

  const submitGiftcard = () => {
    const currentToken = AuthService.getCurrentToken();
    UserService.redeemGiftcard(currentToken.access_token, giftcardCode).then(
      (response) => {
        setUserMessage({
          showMessage: true,
          message: "Innløsning av gavekort vellykket!",
          messageType: "alert-success",
        });
        UserService.getSubscription(currentToken.access_token).then(
          (subscription) => {
            UserService.userSubscriptionLog("Payment Page 3", JSON.stringify(subscription), currentUser);
            setSubscription(subscription);
          }
        );
      },
      (error) => {
        if (error.response.status === 409) {
          setUserMessage({
            showMessage: true,
            message: "Denne koden er allere brukt!",
            messageType: "alert-danger",
          });
        } else {
          setUserMessage({
            showMessage: true,
            message: "Feil kode",
            messageType: "alert-danger",
          });
        }
      }
    );
  };
  
  const goToTerminateSubscription = () => {
    history.push({
      pathname: "/avslutt-abonnement",
    });
    
    //if(terminateExecution===0){
    //  terminateExecution = 1;
    //  set601OfferWidget(true);



    //} else {
      // history.push({
      //  pathname: "/avslutt-abonnement",
      //});
    //}
   
    
  };
  //console.log("subState", subState, campaign);
  return (
    <Card className="card-main">
      <Modal
        className="popup"
        show={popupChangeSub}
        onHide={handleCloseM}
        disabled={disableMode}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Bekreft
            {subState == "TRIALING" ? (
              <> abonnementet</>
            ) : (
              <> abonnementsbytte</>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subState == "TRIALING" ? (
            <>
              Abonnementsendringen vil skje umiddelbart. Du vil ikke lenger ha
              gratis prøveperiode.
            </>
          ) : (
            <>
              Abonnementsendringen vil skje umiddelbart og den nye prisen trer
              først i kraft ved neste betalingsdato.
            </>
          )}
          {currentUser.payment_type == '6' ? ( <></>) : null}
          {currentUser.payment_type == '0' ? ( <></>) : null}
      
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseM}
            disabled={disableMode}
          >
            Avbryt
          </Button>
          <Button
            className="btn-success"
            onClick={handleChangeSub}
            disabled={disableMode}
          >
            Aksepter
          </Button>
        </Modal.Footer>
      </Modal>
      <Card.Header>
        <div className="bold-text">{title}</div>
      </Card.Header>
      <Card.Body className="card-body">
        <Card.Text as="div" className="card-text">
          {/*}
          {subState === "FALSE" && currentUser.payment_type == 0 ? (
            <>
              Ingen betalingsmetode valgt <br />{" "}
            </>
          ) : (
            <> Valgt betaling {renderSwitch(currentUser.payment_type)}</>
          )}{*/}
          <> Valgt betaling {renderSwitch(currentUser.payment_type)}</>
         
          {subState === "ACTIVE" ? (
            <>
              <br />

              {campaign ? (
                <>
                  {subscription.product_id == "42" || subscription.product_id == "1001"  || subscription.product_id == "1002" || subscription.product_id == "2001" || subscription.product_id == "4001" ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      
                    </>
                  )}
                  <br />
                </>
              ) : (
                
                  <>
                    {subscription.product_id == "110" ? (
                      <>
                        Fra 1. januar 2023 er abonnementet løpende til 189 kr per måned. 
                        <br />
                        <br />
                      </>
                    ) : (
                      <>
                        
                      </>
                    )}
                    <br />
                  </>
                  
                
              )}
            </>
          ) : null}

          {subState === "STOPPED" ? (
            <>
              <br />
              Du har avsluttet ditt abonnement
              <br />
              <div className="bold-text">
                Du mister tilgang til Fabel den{" "}
                {formatDateString(subscription.expiry_date)}.<br />
                <br />
              </div>
              <div>
              <div style={{fontSize: '14px'}}>
              Ditt Fabel-abonnement er stoppet, men du kan lytte ut betalingsperioden. Du har fortsatt en kostnadsfri brukerkonto, og kan starte abonnementet igjen når du ønsker. Ønsker du å slette din brukerkonto, kan du kontakte kundeservice på kontakt@fabel.no.
              </div>

              <br />
            
            </div>
            </>
          ) : null}
          {subState === "EXPIREDDEACTIVATED" ? (
            <>
              <br />
              Betalingsmetode utgått
              <br />
              <br />
              
              <div>
                <div style={{fontSize: '14px'}}>
                Du må legge inn et nytt bankkort og så velge abonnement får å kunne lytte videre. Velg 'Endre betalingsmetode', legg inn kortdetaljer, trykk bekreft og gå igjennom autentifisering gjennom banken. Deretter velg det abonnement som passer deg.
                </div>

              <br />
            
              </div>
            </>
          ) : null}
              
          {subState === "TRIALING" ? (
            <>
              <br />
              Ditt abonnementet er på prøvetid
              <br />
              <div className="bold-text">
                Din neste faktureringsdato er{" "}
                {formatDateString(subscription.next_billing_date)}.<br />
                <br />
              </div>
            </>
          ) : null}

          {subState === "FALSE" ? (
            <>
              <br />
              Du har ikke et aktivt abonnement
              <br />
              <br />
            </>
          ) : null}
        </Card.Text>

        <ListGroup className="list-grp">
          <ListGroup.Item>
            {subState === "FALSE" && currentUser.payment_type == 0 ? (
              <>Velg betalingsmetode</>
            ) : (
              <>Endre betalingsmåte</>
            )}

            <button
              className={
                paymentExpand
                  ? " expand-btn expandtransformation"
                  : "expand-btn"
              }
              onClick={() => toggle("payment")}
            >
              <span className="drp-icon">&#10148;</span>
            </button>
            {paymentExpand ? (
              <>
                <Tabs
                  defaultActiveKey={ 
                    subState === "FALSE" && currentUser.payment_type == 0 
                      ? "kort" : 
                    currentUser.payment_type === 0
                      ? "mobil" :
                      currentUser.payment_type === 6
                      ? "vipps"
                      : "kort"
                  }
                  className="mb-3"
                >
                  <Tab eventKey="kort" title="Kort">
                    <Card className="payment-card">
                      <StripeWidget
                        setUserState={setCurrentUser}
                        currentUser={currentUser}
                        currProduct={subscription.product_id}
                        currState={subState}
                      />
                    </Card>
                  </Tab>
                  <Tab eventKey="mobil" title="Mobil">
                    <StrexWidget
                      setCurrentUser={setCurrentUser}
                      currentUser={currentUser}
                      currProduct={subscription.product_id}
                    />
                  </Tab>
                  {currentUser.payment_type === 6 ? (
                    <Tab eventKey="vipps" title="Vipps">
                    <Suspense fallback={<div>Loading...</div>}>
                      
                      <VippsWidget
                        currentProductId={subscription.product_id}
                      />
                    
                    </Suspense>
                    </Tab>
                  ) : (
                     <></>
                  )}
                
                </Tabs>
              </>
            ) : null}
          </ListGroup.Item>
          {currentUser.payment_type === 0 && subState !== "FALSE" ? (
            <ListGroup.Item>
              Se betalingshistorikk
              <button
                className={
                  orderExpand
                    ? " expand-btn expandtransformation"
                    : "expand-btn"
                }
                onClick={() => toggle("order-history")}
              >
                <span className="drp-icon">&#10148;</span>
              </button>
              {orderExpand ? (
                <Card className="payment-history-card">
                  <Card.Title>
                    <br />
                    Få full oversikt over mobilbetalingen
                  </Card.Title>
                  <Card.Text as="div">
                    <Row>
                      Få oversikt over alle dine transaksjoner på strex.no.
                      Registreringen er enkel og rask, og er en sikkerhet for
                      deg som betaler.
                    </Row>
                    <Row className="float-right">
                      <Button variant="link" onClick={goToStrex}>
                        Gå til strex.no
                      </Button>
                    </Row>
                  </Card.Text>
                </Card>
              ) : null}
            </ListGroup.Item>
          ) : null}
          
          {subState === "ACTIVE" || subState === "RENEWFAIL" || (subState === "TRIALING" && !campaign) ? (
            <>
              <ListGroup.Item>
                {terminateSubExpand ? null : <>Stopp abonnement </>}
                <button
                  className={
                    terminateSubExpand
                      ? " expand-btn expandtransformation"
                      : "expand-btn"
                  }

                  onClick={() => toggle("terminate-sub")}
                >
                  <span className="drp-icon">&#10148;</span>
                </button>
                {terminateSubExpand ? (
                  <Card className="terminate-sub-card">
                    <Card.Title>
                      <br />
                      Stopp abonnement
                    </Card.Title>
                    <Card.Text as="div" className="terminate-sub-text text-align-center">
                        
                        <br />
                        {offer601Widget===true ? 
                          <div id="601-offer-widget" style={{display: 'none'}}> 
                            <div className="card-title h5">Vi vil veldig gjerne at du blir hos oss.</div> 
                            <br />
                            <div className="card-title h4">🐣 PÅSKETILBUD 🐣 </div> 
                            <div className="card-title h5">Fabel Familie i 10 uker for kun 99 kroner</div> 
                            <div className="explanatory-text">Normalt 189 kr/mnd – gjelder først etter 10 uker.</div> 
                            <br />
                            <br />
                            <div className="card-title h5">Slå til nå, så kan du høre krim og andre lydbøker i påsken. </div> 
                            <div className="card-title h5">Du får også med deg nyhetene fra 2022 som slippes for strømming fra 1. mai.</div> 
                            <br />
                            <div>
                              <Button variant="link" className="link-underline" value="601" onClick={handle601Product}>Jeg vil ha dette tilbudet</Button>
                            </div>
                            <br />
                          </div>
                           : ''}
                          

                        
                        
                        <div> 
                                {/*campaignAvsluttProduct.length > 0 ? 
                                  <>
                                    <div style={{borderBottom: '1px dashed #000000', padding: '10px'}}>
                                      <div style={{fontSize: '15px', width: '90%', textAlign: 'center', margin:' 0px auto'}}>
                                        Er du helt sikker på at du vil forlate oss?
                                        <br />
                                        <br />
                                        Bli, og få Fabel i to måneder til 50 % rabatt!
                                        <br />
                                        <span style={{fontSize: '15px', fontWeight: 'bold'}}>Ubegrenset lytting til halv pris.</span>
                                        <br />
                                        <br />
                                        
                                      </div>
                                      {campaignAvsluttProduct.map((value, key) => {
                                              const campaignAvsluttExceptionProductt = value.exceptionProduct.split(", ");
                                              
                                              if(campaignAvsluttExceptionProductt.includes(subscription.product_id)==false){
                                              return <>
                                                <Button  style={{fontSize: '15px', fontWeight: 'bold'}} variant="link" className="link-underline" value={value.id} onClick={() => handleCampaignProduct(value.id)}>{value.plainName}</Button>
                                                <br />
                                                {value.concurrentStreams.toString()}{" "}
                                                {value.concurrentStreams === 1 ? (
                                                  <>samtidig strømming.</>
                                                ) : (
                                                  <>samtidige strømminger.</>
                                                )}{" "}
                                                {value.profiles.toString()}{" "}
                                                {value.profiles == 1 ? <>profil.</> : <>profiler.</>}
                                                  <br /><br />
                                               </>
                                             }
                                            
                                      })}
                                    </div>
                                  </>
                                  :  '' */}
                                {campaignAvsluttExceptionProductt.includes(subscription.product_id)===false 
                                  
                                  && campaignAvsluttStartDate 
                                  &&  campaignAvsluttEndDate 
                                  && campaignAvsluttProduct.length > 0 ? 
                                  (
                                    <>
                                    <div>
                                      <div style={{fontSize: '15px', width: '90%', textAlign: 'center', margin:' 0px auto'}}>
                                        
                                        {campaignAvsluttProduct[0].text1 ? (<> {campaignAvsluttProduct[0].text1} <br /><br /> </>) : '' }
                                        {campaignAvsluttProduct[0].text2 ? (<> {campaignAvsluttProduct[0].text2} <br /> </>) : '' }
                                        {campaignAvsluttProduct[0].text3 ? (<><span style={{fontSize: '15px', fontWeight: 'bold'}}>{campaignAvsluttProduct[0].text3}</span><br /></>) : ''}
                                        {campaignAvsluttProduct[0].text4 ? (<><span style={{fontSize: '13px'}}>{campaignAvsluttProduct[0].text4}</span><br /></>) : ''}
                                        {campaignAvsluttProduct[0].text5 ? (<><span style={{fontSize: '13px'}}>{campaignAvsluttProduct[0].text5}</span><br /></>) : ''}
                                        
                                      </div>
                                      <Button variant="link" className="link-underline" value={campaignAvsluttProduct[0].id} onClick={() => handleCampaignProduct(campaignAvsluttProduct[0].id)}>Ja takk, jeg vil ha dette abonnementet</Button>
                                    </div>
                                   </>
                                )
                               : null }
                              
                        <Button
                        variant="link"
                        className="link-underline"
                        onClick={goToTerminateSubscription}
                        >
                        Jeg vil stoppe abonnementet
                        </Button>
                      </div> 
                      
                      
                      <br />
                      <br />
                    </Card.Text>
                    
                  </Card>
                ) : null}
              </ListGroup.Item>
            </>
          ) : null}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default PaymentCard;
