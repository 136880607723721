import React, { useEffect, useState } from 'react'
import { Card, Form, FormControl, Button, Modal } from 'react-bootstrap'
import { Redirect } from "react-router-dom";

import AuthService from '../services/AuthService'
import UserService from '../services/UserService'
import '../styles/terminate-sub.css'
import { useHistory } from 'react-router'

const TerminateSubscripton = () => {
    const [redirect, setRedirect] = useState(null);
    const [otherText, setOtherText] = useState('')
    const [otherTextKomplisert, setOtherTextKomplisert] = useState('')
    const [checkedRadio, setCheckedRadio] = useState(4)
    const [checkedRadioKomplisert, setCheckedRadioKomplisert] = useState(23)
    const [userReady, setUserReady] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
      const [currentUser, setCurrentUser] = useState({
        username: "",
      });
    const history = useHistory();
    const [showPopup, setShowPopup] = useState(false)
    const [popMsg, setPopUpMsg] = useState('')

    


    const handleChangeOtherText = e => {
        setOtherText(e.currentTarget.value)
    }
    const handleChangeOtherTextKomplisert = e => {
        setOtherTextKomplisert(e.currentTarget.value)
    }

    useEffect(() => {
        const currentToken = AuthService.getCurrentToken()
        UserService.getUserFromToken(currentToken.access_token).then(
        (user) => {
            UserService.userGetLog("Terminate Page 1", user,  JSON.stringify(currentUser));
          console.log('success getUserFromToken');
          setCurrentUser(user);
          setUserReady(true);
        
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
              
              const errorMsg = error.response.data.message;
              if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
                UserService.userGetLog("error Terminate Page: "+currentToken.access_token, errorMsg, JSON.stringify(currentUser));
                AuthService.logout();
                setRedirect({ redirect: "/logg-inn" });
              }
              console.log("new error  mypage 1", error.response.data.message); // Handle error message here
            } else {
              console.log('An unexpected error occurred');
            }
          });
    }, [])

    const handleSubmit = () => {
        setIsDisabled(true)
        console.log(otherText, checkedRadio, checkedRadioKomplisert)
        let comment = "";
        let commentK = "";
        switch(checkedRadio) {
            case 0:
                comment = "For dyrt.";
                break;
            case 1:
                comment = "For lite relevant innhold.";
                break;
            case 2:
                comment = "For ustabil tjeneste.";
                break;
            case 3:
                comment = "Hører for lite på lydbøker"
                break;
            case 4:
                comment = otherText;
                break;
            default:
                break;
            }

        switch(checkedRadioKomplisert) {
            case 21:
                commentK = "Tekniske problemer med appen";
                break;
            case 22:
                commentK = "Å velge lydbok";
                break;
            case 23:
                commentK = otherTextKomplisert;
                break;
            default:
                break;
            }

        

        const comments = comment+' - '+commentK;
        
        const currentToken = AuthService.getCurrentToken()    

        UserService.terminateSubscription(currentToken.access_token, comments)
            .then((response) => {
                UserService.cancelSubscriptionLog("success", response, comments, currentUser.mobile);
                setPopUpMsg('Du har fremdeles en kostnadsfri brukerkonto, og kan starte opp igjen med et nytt abonnement når du måtte ønske.');
                setShowPopup(true);   
                console.log(response);
            }, (error) => {
                console.log(error);
                UserService.cancelSubscriptionLog("error", error, comments, currentUser.mobile);
                setPopUpMsg('Noe gikk galt vi klarte ikke å stoppe ditt abo');
                setShowPopup(true);  
            })

    }

    const handleClose = () => {
        setShowPopup(false)
    };

    const handleRadioChangeKomplisert = e => {
        
        setCheckedRadioKomplisert(parseInt(e.currentTarget.id))
    }

    const handleRadioChange = e => {
        
        document.getElementById('warning-message-3').style.display = "none";
         document.getElementById('warning-message-0').style.display = "none";
         document.getElementById('warning-message-2').style.display = "none";
        setCheckedRadio(parseInt(e.currentTarget.id))
        document.getElementById('warning-message-'+e.currentTarget.id).style.display = "block";
    }

    const goToMyPage = () => {
        history.push({
            pathname: '/min-side'
        })
    }
     if (redirect) {
        return <Redirect to={redirect.redirect} />;
      }
    return (
        <Card className="terminate-subscription-card">

    <Modal className="popup"show={showPopup} onHide={goToMyPage}>
        <Modal.Header closeButton>
        <Modal.Title>Abonnementet er stoppet.</Modal.Title>
        </Modal.Header>

        <Modal.Body>{popMsg}</Modal.Body>
        <Modal.Footer>
        <div style={{fontSize: '12px', float: 'left', width: '75%'}}>
        Ønsker du ikke å ha en kostnadsfri brukerkonto kan du kontakte kundeservice på kontakt@fabel.no
        </div>
        <div style={{textAlign: 'right', float: 'right', width: '20%'}}>
        <Button className="btn-success" onClick={goToMyPage}>
            Lukk
        </Button>
        </div>
        </Modal.Footer>

    </Modal>

            <Card.Title className="terminate-subscription-card-title">
                Er du helt sikker?
            </Card.Title>

            <Card.Text>
                Vi legger kontinuerlig  ut nye lydbøker og -serier i appen, slik at det alltid er noe tilgjengelig for enhver smak.<br/><br/>
                For at vi skal kunne forbedre oss setter vi stor pris på at du forteller oss hvorfor du stoppet abonnementet:
                
                <Form.Check
                id="4"
                onChange={handleRadioChange}
                checked={checkedRadio === 4}
                type="radio"
                name="terminateradio"
                label="Annet"
                />
                <br/>
                <FormControl
                className="fabel-input"
                onChange={handleChangeOtherText}
                />

                <Form.Check
                id="3"
                onChange={handleRadioChange}
                checked={checkedRadio === 3}
                type="radio"
                name="terminateradio"
                label="Hører for lite på lydbøker"
                
                />
                <Card.Text id="warning-message-3">
                    <div className="warning-green" >Lydbøker lar deg utnytte tiden din bedre ved å lytte mens du gjør andre ting, som å kjøre, trene eller gjøre husarbeid. På den måten kan du konsumere litteratur når du ellers ikke ville hatt tid. <br /><a href="/min-side">Her kan du finne et abonnement som passer deg bedre</a> </div>
                </Card.Text>

                <Form.Check
                id="1"
                checked={checkedRadio === 1}
                type="radio"
                onChange={handleRadioChange}
                name="terminateradio"
                label="For lite relevant innhold"
                
                />

                <Form.Check
                id="2"
                onChange={handleRadioChange}
                checked={checkedRadio === 2}
                type="radio"
                name="terminateradio"
                label="Det er komplisert"
                
                />
                <Card.Text id="warning-message-2">
                    <div className="warning-green" >
                        <Form.Check
                            id="21"
                            onChange={handleRadioChangeKomplisert}
                            checked={checkedRadioKomplisert === 21}
                            type="radio"
                            name="komplisert"
                            label="Tekniske problemer med appen"
                            
                            />
                            <Form.Check
                            id="22"
                            onChange={handleRadioChangeKomplisert}
                            checked={checkedRadioKomplisert === 22}
                            type="radio"
                            name="komplisert"
                            label="Å velge lydbok"
                            
                            />
                            <Form.Check
                            id="23"
                            onChange={handleRadioChangeKomplisert}
                            checked={checkedRadioKomplisert === 23}
                            type="radio"
                            name="komplisert"
                            label="Annet"
                            
                            />
                            <br/>
                            <FormControl
                            className="fabel-input"
                            onChange={handleChangeOtherTextKomplisert}
                            />
                         
                    </div>
                </Card.Text>
                

                <Form.Check
                id="0"
                label="For dyrt"
                type="radio"
                name="terminateradio"
                onChange={handleRadioChange}
                checked={checkedRadio === 0}
                />
               <Card.Text id="warning-message-0">
                    <div className="warning-green" >
                    Prisene våre er utformet for å være så rimelige som mulig. Fra kun 149 kroner per mnd kan du lage opptil seks profiler. 
                    <br /> <a href="/min-side">Her kan du finne et abonnement Som passer bedre</a>
                    </div>
                </Card.Text>

                
                
                
                <br/>
                <Button
                disabled={isDisabled}
                className="btn-fabel" 
                onClick={handleSubmit}
                > STOPP ABONNEMENTET </Button><br/>
                <Button variant="link" className="link-underline" onClick={goToMyPage} >Avbryt</Button>
            </Card.Text>
        </Card>
    )
}

export default TerminateSubscripton
