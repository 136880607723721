import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import MyPageCard from "./widgets/MyPageCard";
import PaymentCard from "./widgets/PaymentCard";
import BookCard from "./widgets/BookCard";
import SubscriptionCard from "./widgets/SubscriptionCard";
import "../styles/mypage.css";

const MyPage = ({ setTopBanner, link}) => {
  //define state
  const [redirect, setRedirect] = useState(null);
  const [subState, setSubState] = useState("DEACTIVATED");
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    username: "",
  });
  //console.log("subscription currentUser", subscription, currentUser);
  const yourDate = new Date(currentUser.date_joined);
  const cutoffDate = new Date('2024-03-07T00:00:00.000Z');
  const isBeforeCutoff = yourDate < cutoffDate;
  const [subscription, setSubscription] = useState(null);
  const [subscriptionReady, setSubscriptionReady] = useState(false);

  //check auth status
  useEffect(() => {
    const currentToken = AuthService.getCurrentToken();

    if (!currentToken) {
      console.log('error currentToken');
      setRedirect({ redirect: "/" });
    } else {
      UserService.getUserFromToken(currentToken.access_token).then(user => {
          UserService.userGetLog("success MyPage 1: "+currentToken.access_token, JSON.stringify(user), JSON.stringify(currentUser));
          //console.log('success getUserFromToken', user);
          setCurrentUser(user);
          setUserReady(true);
      }).catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          
          const errorMsg = error.response.data.message;
          if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
            UserService.userGetLog("error MyPage 4: "+currentToken.access_token, errorMsg, JSON.stringify(currentUser));
            AuthService.logout();
            setRedirect({ redirect: "/logg-inn" });
          }
          console.log("new error  mypage 1", error.response.data.message); // Handle error message here
        } else {
          console.log('An unexpected error occurred');
        }
      });

      /*UserService.getUserFromToken(currentToken.access_token).then(
        (user) => {
          UserService.userGetLog("success MyPage 1: "+currentToken.access_token, JSON.stringify(user), JSON.stringify(currentUser));
          console.log('success getUserFromToken', user);
          setCurrentUser(user);
          setUserReady(true);
        },
        (error) => {
          const errorMsg = error.response.data.message;
          UserService.userGetLog("error MyPage 1: "+currentToken.access_token, JSON.stringify(error), JSON.stringify(currentUser));
          
          if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
            AuthService.logout();
            setRedirect({ redirect: "/logg-inn" });
          }
          if(JSON.stringify(error).includes("Token not found") || JSON.stringify(error).includes("Invalid Token") || JSON.stringify(error).includes("Token expired")){
            AuthService.logout();
            setRedirect({ redirect: "/logg-inn" });
          }
          console.log('error getUserFromToken', error);
          setRedirect({ redirect: "/" });
        }
      ).catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          UserService.userGetLog("error MyPage 4: "+currentToken.access_token, JSON.stringify(error), JSON.stringify(currentUser));
          const errorMsg = error.response.data.message;
          if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
            AuthService.logout();
            setRedirect({ redirect: "/logg-inn" });
          }
          console.log("new error", error.response.data.message); // Handle error message here
        } else {
          console.log('An unexpected error occurred');
        }
      });;*/

      //get sub

      UserService.getSubscription(currentToken.access_token).then((subscription) => {
          UserService.userSubscriptionLog("success MyPage 2: "+currentToken.access_token, JSON.stringify(subscription), JSON.stringify(currentUser));
          if (subscription.state === "3") {
            setSubState("ACTIVE");
          }
          if (subscription.state === "10") {
            setSubState("DEACTIVATED");
            //setProductID(subscription.product_id);
          }
          if (subscription.state === "1") {
            setSubState("TRIALING");
          }
          if (subscription.state === "6") {
            setSubState("STOPPED");
          }
          setSubscription(subscription);
          setSubscriptionReady(true);
      }).catch(error => {
        setSubscription({
          id: 99,
          sub: true,
          name: "No subscription",
          price: 0,
        });
        setSubscriptionReady(true);
        if (error.response && error.response.data && error.response.data.message) {
          
          const errorMsg = error.response.data.message;
          if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
            UserService.userSubscriptionLog("error MyPage 3: "+currentToken.access_token, errorMsg, JSON.stringify(currentUser));
            AuthService.logout();
            setRedirect({ redirect: "/logg-inn" });
          }
          if(errorMsg == "User has no active subscription."){
            setSubState("DEACTIVATED"); 
          }
          console.log("errorMsg", ">"+errorMsg+"<", ">"+error.response.data.message+"<")
          console.log("new error  mypage 2", error.response.data.message); // Handle error message here
        } else {
          console.log('An unexpected error occurred');
        }
      });
      
    }
  }, []);

  //redirect if not logged in
  if (redirect) {
    return <Redirect to={redirect.redirect} />;
  }
  return (
    <div>
      <div className="mypage">
        <h3 className="title-mypage">Konto</h3>
        <div style={{float: 'right', marginTop: '-25px'}}>Vi har oppdatert <a target="_blank" style={{color: '#1e1e40'}} href="https://fabel.no/vilkar">vilkårene</a>.</div>
        
      </div>
      <div style={{clear:'both'}}></div>
      {userReady && subscriptionReady ? (
        <>
          <MyPageCard
            title={<div className="bold-text">Brukeropplysninger</div>}
            setCurrentUser={setCurrentUser}
            currentUser={currentUser}
          />
          <SubscriptionCard
            setTopBanner={setTopBanner}
            title={<div className="bold-text">Abonnementsinformasjon</div>}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            subscription={subscription}
            setSubscription={setSubscription}
            link={link}
          />
          <PaymentCard
            title={<div className="bold-text">Fakturering</div>}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            subscription={subscription}
            setSubscription={setSubscription}
          />
          <BookCard
            title={<div className="bold-text">Kjøpte bøker</div>}
            user={currentUser}
          />
        </>
      ) : null}
    </div>
  );
};

export default MyPage;
